.c-video-center {
  $element: &;

  &--channel-page {
    overflow-x: visible;
  }

  &__title {
    margin-bottom: 1.5625rem;

    h3 {
      margin: 0 0 10px 0;
    }

    @include breakpoint(medium) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h3 {
        margin: 0;
      }
    }
  }

  &__slider {
    margin: 0 -0.9375rem;
    position: relative;

    .slick-track {
      margin: 0;
    }

    .slick-arrow {
      background: rgba(255, 255, 255, 0.6);
      color: transparent;
      height: 3.125rem;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 0.25s ease;
      width: 3.125rem;
      z-index: 5;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 2.1875rem;
        color: $gb_black;
        transform: translate(-50%, -50%);
      }

      &.slick-disabled {
        opacity: 0;
        cursor: default;
      }

      &.slick-prev {
        left: 0;

        &:after {
          @include web20-icon('chevron-large-left');
        }
      }

      &.slick-next {
        right: 0;

        &:after {
          @include web20-icon('chevron-large-right');
        }
      }

      @include breakpoint(xxlarge) {
        &:after {
          color: $gb_grey_400;
        }

        &:hover:after {
          color: $gb_black;
        }

        &.slick-prev {
          left: -2.1875rem;
        }

        &.slick-next {
          right: -2.1875rem;
        }
      }
    }
  }

  &__channel {
    min-height: 12rem;
    margin: 0 -0.9375rem;

    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
    }

    #{$element}__item {
      @include breakpoint(medium) {
        flex-basis: 50%;
      }

      &--large {
        @include breakpoint(medium) {
          flex-basis: 100%;

          h4:last-child {
            margin-bottom: 1.5625rem;
          }
        }

        .c-text-video {
          border: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    @include breakpoint(xxlarge) {
      #{$element}__item {
        flex-basis: 33.333333%;

        &--large {
          flex-basis: 100%;

          .image {
            flex-basis: 66.666666%;
          }

          .text {
            flex-basis: 33.333333%;
          }
        }
      }
    }

    &__selector {
      margin-bottom: 2.5em;
      position: relative;
      width: 100%;

      &__trigger {
        background: $gb_white;
        border: 1px solid $gb-grey_100;
        color: var(--gb-brand-primary-light);
        cursor: pointer;
        font-size: 0.75rem;
        font-weight: $font-weight-bold;
        padding: 0.75rem 0.9375rem 0.625rem 0.9375rem;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;

        &:hover {
          text-decoration: none;
        }
      }

      &__content {
        background-color: $gb_white;
        z-index: 6;
        box-shadow: $default-box-shadow;
        right: 0;
        left: 0;
        cursor: default;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            position: relative;
            padding: 0;
            cursor: pointer;
          }

          li.active {
            cursor: default;
            padding: 0.625rem 1.125rem;
          }
        }

        a {
          display: block;
          cursor: pointer;
          padding: 0.625rem 1.125rem;

          &:hover,
          &:active {
            text-decoration: none;
            background: $gb-grey_075;
          }

          &::before {
            display: none;
          }
        }
      }

      &.dropdown--active &__trigger {
        z-index: 10;
        box-shadow: $default-box-shadow;
        border-color: $gb-grey_200;
        background-color: $gb-grey_025;
      }
    }
  }

  &__button {
    display: flex;
    flex-basis: 100%;

    .button {
      width: 100%;
    }
  }

  &__item {
    margin-bottom: 3.75rem;
    padding: 0 0.9375rem;

    &#{$element}__item--large .text h3 {
      margin-bottom: 0.9375rem;
      font-size: 1.5625rem;
      line-height: 1.75rem;
    }

    .image {
      @include playIcon(50, 75);
      background: $gb_grey_060 no-repeat center;
      background-size: cover;
      margin-bottom: 0.9375rem;
      cursor: pointer;
      position: relative;

      &:hover .description {
        opacity: 1;
      }

      // Give item an aspect ratio
      &::before {
        padding-bottom: 56.25%;
        display: block;
        content: '';
      }

      &.noconsent {
        cursor: default;

        &::after {
          display: none;
        }
        &:hover .description {
          opacity: 0;
        }
      }

      &::after {
        @include web20-icon('play');
        background: rgba(255, 255, 255, 0.5);
        width: 2.8125rem;
        height: 2.8125rem;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 1.25rem;
        line-height: 2.8125rem;
        text-indent: 3px;
        text-align: center;
        border-radius: 50%;
        border: $border-black;
        transform: translate(-50%, -50%);

        @include breakpoint(medium) {
          width: 4.6875rem;
          height: 4.6875rem;
          font-size: 1.875rem;
          line-height: 4.6875rem;
          text-indent: 4px;
        }
      }
    }

    .description {
      background: rgba(0, 0, 0, 0.75);
      padding: 1.25rem 1.25rem 5rem 1.25rem;
      opacity: 0;
      color: $gb_white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
      transition: opacity 0.25s ease;

      p {
        margin: 0;
      }

      .button {
        padding-left: 1.5625rem;
        position: absolute;
        bottom: 1.25rem;
        left: 1.25rem;
        color: $gb_white;
        border-color: $gb_white;
      }
    }

    .text h3 {
      margin: 0;
      font-size: 0.9375rem;
      line-height: 1.5rem;
      text-transform: none;
    }
  }
}
