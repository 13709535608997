@use 'sass:math';

// mixin for slider
@mixin slider-content() {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    max-height: 70%;
    overflow: hidden;

    &:only-child {
      max-height: 100%;
    }

    .block {
      display: block;
    }
  }

  &__link {
    height: 100%;
    max-height: 30%;

    a {
      max-height: 100%;

      &.button--white,
      &.button--black:hover {
        color: $gb_white;
      }

      &.button--black,
      &.button--white:hover {
        color: $gb_black;
      }
    }
  }
}

// --------------------------
// Slick slider vendor
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir='rtl'] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  &:not(.slick-active) {
    button,
    div[role='button'] {
      visibility: hidden;
    }
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

// --------------------------
// Slider on homepage
.slider-container {
  background: var(--gb-brand-primary);
  height: calc((100vw * 7) / 24);
  min-height: 250px;
  position: relative;

  // --------------------------
  .slick-slider {
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    justify-content: center;
  }

  .slick-list,
  .slick-track {
    height: 100%;
    width: 100%;
  }

  // --------------------------
  // Arrows
  .slick-arrow {
    width: 1.75rem;
    height: 1.75rem;
    overflow: hidden;
    position: absolute;
    top: 50%;
    z-index: 5;
    font-size: 1.125rem;
    color: transparent;
    transform: translateY(-50%);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      color: $gb_black;
      transform: translate(-50%, -50%);
      transition: color $gb_transitionNormal $gb_transitionTiming;
    }

    &.slick-prev {
      left: 5.625rem;

      &:before {
        @include web20-icon('arrow-link-bold-left');
      }
    }

    &.slick-next {
      right: 5.625rem;

      &:before {
        @include web20-icon('arrow-link-bold-right');
      }
    }
  }

  // --------------------------
  // Dots
  .slick-dots {
    bottom: 1.25rem;
    list-style-type: none;
    padding: 0;
    position: absolute;

    @include breakpoint(medium) {
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      margin: 0 0.375rem 0.375rem;
      padding: 0;
      float: left;

      &.slick-active button {
        background-color: var(--gb-brand-primary-light);
      }
    }

    button {
      background: $gb_white;
      width: 1.875rem;
      height: 3px;
      display: block;
      overflow: hidden;
      color: transparent;
    }
  }

  // --------------------------
  // Single slide (.slick-slide)
  .slick-track > div {
    width: 100%;
    height: 100%;
  }

  .slick-slide > div {
    height: 100%;

    & > div {
      height: 100%;
      background: no-repeat center;
      background-size: cover;
    }
  }

  .slide-content {
    @include word-wrap();
    @include slider-content();
    padding: calc(25vw / 4) 2.5em 3.4375rem calc(100vw / 10);
    color: $gb_white;

    @include breakpoint(medium) {
      padding: calc(25vw / 4) 0 0 calc(100vw / 10);
    }

    &--black {
      color: $gb_black;
    }
  }

  // --------------------------
  // Slide content
  .subheadline {
    text-transform: uppercase;
  }

  h2 b,
  span.h2 b {
    display: block;
  }

  a.button .icon::before {
    margin-top: -2px;
    line-height: 1rem;
  }

  a::before {
    content: '';
  }

  .slide-content__background-image > .c-tpp-area {
    height: 100%;
  }

  .slide-content__background-image > .c-tpp-area__bgcrop {
    position: relative;
  }

  .slide-content-container {
    @include xy-gutters($gutters: $grid-container-padding, $gutter-type: padding);
    max-width: $grid-container;
    margin: 0 auto;
    height: 100%;

    .c-tpp-area {
      height: 100%;
      width: 100%;
    }
  }

  .video-container {
    height: 100%;
    position: relative;

    .video-player {
      height: 100%;
    }

    .play-video {
      background: no-repeat center;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;

      // Play icon
      span {
        background: rgba(255, 255, 255, 0.3);
        width: 4.6875rem;
        height: 4.6875rem;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 3.125rem;
        border-radius: 50%;
        border: $border-black;
        transform: translate(-50%, -50%);

        &:before {
          position: absolute;
          top: 50%;
          left: 52%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .video-preview-container {
    &__video {
      height: 100%;
    }

    &__video-title {
      @include word-wrap();
      @include slider-content();
      color: $gb_white;
      padding: calc(25vw / 4) 2.5em 2.5em calc(100vw / 10);

      @include breakpoint(medium) {
        padding: calc(25vw / 4) 0 0 calc(100vw / 10);
      }

      &--black {
        color: $gb_black;
      }

      & .video-subheadline {
        text-transform: uppercase;
      }
    }

    &__video-module {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      margin: 0;
      position: relative;

      a {
        height: 100%;
        left: 0;
        margin-left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &::before {
          content: none;
        }
      }

      img {
        height: 4.875rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 4.875rem;
        z-index: 5;
      }
    }
  }

  .mi-player {
    background: transparent;
    text-align: center;
  }

  .mi-controls {
    position: relative;
    margin: 0 auto;
    bottom: 2rem !important;
  }

  object.mi-content {
    width: 100% !important;
    @include breakpoint(medium) {
      width: 66% !important;
    }
  }

  .mi-player,
  .mi-content {
    width: auto !important;
    height: calc((100vw * 7) / 24) !important;
    min-height: 250px;
  }

  &--homepage,
  &--above-breadcrumb {
    height: $grid-container * math.div(7, 24);
    @include breakpoint(medium) {
      height: math.div(6, 16) * 100%;
    }

    &::before {
      display: block;
      content: '';
      width: 100%;
      padding-bottom: $grid-container * math.div(7, 24);
      @include breakpoint(medium) {
        padding-bottom: math.div(6, 16) * 100%;
      }
    }

    .mi-player,
    .mi-content {
      height: math.div(6, 16) * 100% !important;

      &::before {
        display: block;
        content: '';
        width: 100%;
        padding-bottom: math.div(6, 16) * 100%;
      }
    }
  }

  &--color-white .slick-arrow:before {
    color: $gb_white;
  }

  &--hero-tile {
    max-width: $grid-container;
    height: math.div(($grid-container * 7), 24);
    margin: 0 auto;

    .slide-content-container {
      padding: 0;
    }

    .slick-dots {
      bottom: 0;
      margin-bottom: 1.875rem;
    }

    .slide-content {
      @include slider-content();
      width: 100%;
      padding: 7.1875rem 2.5em 3.4375rem 2.5em;

      @include breakpoint(medium) {
        padding: 7.1875rem 0 0 2.5em;
      }

      h2 {
        margin-bottom: 2.1875rem;
      }
    }
  }
}
