// Global Variables
// -----------------------------
:root {
  --gb-brand-primary: #{$platform-primary};
  --gb-brand-primary-light: #{$platform-primary-light};
  --gb-brand-primary-dark: #{$platform-primary-dark};
  --gb-brand-secondary-emerald: #{$platform-primary-light};
  --product-tiles-new-flag: #{$platform-primary-light};

  // general Colors
  --white: #{$gb_white};
  --white-00: #{$gb_white_00};
  --white-40: #{$gb_white_40};
  --white-70: #{$gb_white_70};
  --white-80: #{$gb_white_80};
  --black: #{$gb_black};
  --black-00: #{$gb_black_00};
  --black-08: #{$gb_black_08};
  --black-26: #{$gb_black_26};
  --black-70: #{$gb_black_70};
  --black-80: #{$gb_black_80};
  --black-82: #{$gb_black_82};
  --cc-actions: #{$gb_cc_actions};
  --gdds_cl15: #{$gb_gdds_cl15};

  // nordics full Colors also used by kolo
  --storm: #{$storm};
  --wave: #{$wave};
  --sky: #{$sky};
  --norway: #{$norway};
  --cliff: #{$cliff};
  --stone: #{$stone};
  --porcelain: #{$porcelain};
  --earth-clay: #{$earth-clay};
  --earth-sand: #{$earth-sand};
  --light-teal: #{$light-teal};

  // Nordic section spacing also used in kolo
  --spacing-nordics-mobile: #{$spacing-nordics-mobile};
  --spacing-nordics-desktop: #{$spacing-nordics-desktop};

  // nord rgba colors also used in kolo
  --color-backdrop: #{$color-backdrop};
  --color-black-36: #{$color-black-36};
  --color-black-06: #{$color-black-06};

  // headline weights
  --h1-weight: #{$font-weight-light};
  --h2-weight: #{$font-weight-light};
  --h3-weight: #{$font-weight-light};
  --h4-weight: #{$font-weight-bold};
  --h5-weight: #{$font-weight-bold};
  --h6-weight: #{$font-weight-bold};

  // headline transform
  --hd-transform: #{$text-transform-up};

  // gdds typography - line height - h1
  --gdds-line-height-h1-xl: #{$gdds-line-height-h1-xl};
  --gdds-line-height-h1-l: #{$gdds-line-height-h1-l};
  --gdds-line-height-h1-m: #{$gdds-line-height-h1-m};
  --gdds-line-height-h1-s: #{$gdds-line-height-h1-s};

  // gdds typography - font size - h1
  --gdds-font-size-h1-xl: #{$gdds-font-size-h1-xl};
  --gdds-font-size-h1-l: #{$gdds-font-size-h1-l};
  --gdds-font-size-h1-m: #{$gdds-font-size-h1-m};
  --gdds-font-size-h1-s: #{$gdds-font-size-h1-s};

  // spacing
  --spacing-xxxxs: #{$spacing-xxxxs};
  --spacing-s: #{$spacing-s};
  --spacing-m: #{$spacing-m};
  --spacing-l: #{$spacing-l};
  --spacing-xl: #{$spacing-xl};
}

html {
  scroll-behavior: smooth;
}

.nord {
  --gb-brand-primary: #{$nord-primary};
  --gb-brand-primary-light: #{$nord-primary-light};
  --gb-brand-primary-dark: #{$nord-primary-dark};
  --gb-brand-secondary-emerald: #{$nord-secondary-emerald};
  --product-tiles-new-flag: #{$nord-secondary-emerald};

  // headline weights
  --h1-weight: #{$font-weight-regular};
  --h2-weight: #{$font-weight-regular};
  --h3-weight: #{$font-weight-regular};

  // h4-6 are bold by default

  // headline transform
  --hd-transform: #{$text-transform-up};
}

.kolo {
  --gb-brand-primary: #{$kolo-primary};
  --gb-brand-primary-light: #{$kolo-primary-light};
  --gb-brand-primary-dark: #{$kolo-primary-dark};
  --gb-brand-secondary-emerald: #{$kolo-secondary-emerald};
  --product-tiles-new-flag: #{$kolo-secondary-emerald};

  // headline weights
  --h1-weight: #{$font-weight-bold};
  --h2-weight: #{$font-weight-bold};
  --h3-weight: #{$font-weight-regular};

  // h4-6 are bold by default

  // headline transform
  --hd-transform: #{$text-transform-up};
}

.twyford {
  --gb-brand-primary: #{$twyford-primary};
  --gb-brand-primary-light: #{$twyford-primary-light};
  --gb-brand-primary-dark: #{$twyford-primary-dark};
  --gb-brand-secondary-emerald: #{$twyford-secondary-emerald};
  --product-tiles-new-flag: #{$twyford-secondary-emerald};

  // headline weights
  --h1-weight: #{$font-weight-light};
  --h2-weight: #{$font-weight-light};
  --h3-weight: #{$font-weight-light};

  // h4-6 are bold by default

  // headline transform
  --hd-transform: #{$text-transform-cap};
}

// Global typo
// -----------------------------
body {
  @include hyphens();
  background: $body-background;
  color: $gb_black;
  font-family: $body-font-family;
  font-size: $gb_fontSizeBase;
  line-height: $gb_lineHeightBase;
  -webkit-overflow-scrolling: touch;
}

// Helper classes
// -----------------------------
.bold,
b {
  font-weight: $font-weight-bold;
}

.has {
  &--background-spacing {
    padding: 3.4375rem 0 2.8125rem;

    > .grid-container {
      > h1,
      > .h1,
      > h2,
      > .h2,
      > h3,
      > .h3,
      > h4,
      > .h4,
      > h5,
      > .h5,
      > h6,
      > .h6 {
        margin-top: 0;
      }
    }
  }

  &--background {
    &--grey-050 {
      background-color: $gb_grey_050;
    }
  }
}

// Global Background Styles
// -----------------------------
.bg-white-teaser {
  .c-tiles__wrapper {
    background-color: $gb_grey_050;
  }
}

.bg-grey-teaser {
  background-color: $gb_grey_055;
}

.bg--sunlight-dark,
.bg-sunlight-dark-teaser {
  background-color: $gb_yellow;
}

.bg--sky-dark,
.bg-sky-dark-teaser {
  background-color: $gb_dark-grey-blue;

  .title--block {
    color: $gb_white;
  }
}

.bg--ocean-dark,
.bg-ocean-dark-teaser {
  background-color: $gb_turquoise;

  .title--block {
    color: $gb_white;
  }
}

.bg--forest-dark,
.bg-forest-dark-teaser {
  background-color: $gb_dark_forest;

  .title--block {
    color: $gb_white;
  }
}

.bg--wood-dark,
.bg-wood-dark-teaser {
  background-color: $gb_brown;

  .title--block {
    color: $gb_white;
  }
}

.bg--light-grey,
.bg-light-grey-teaser {
  background-color: $gb_grey_060;
}

.bg--medium-grey,
.bg-medium-grey-teaser {
  background-color: $gb_grey_100;
}

.bg--rock-dark,
.bg-rock-dark-teaser {
  background-color: $gb_grey_750;

  .title--block {
    color: $gb_white;
  }
}

.bg--blue-grey,
.bg-blue-grey-teaser {
  background-color: $gb_dark-grey-blue;

  h2 {
    color: $gb_white;
  }

  .title--block {
    color: $gb_white;
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    &::after {
      color: $gb_grey_100;
    }

    &:hover::after {
      color: $gb_white;
    }
  }
}

.display-block {
  display: block !important;
}

.mi-player {
  ~ .mi-player {
    display: none;
  }
}

@media print {
  footer,
  #page-header {
    display: none;
  }

  main {
    padding-top: 0 !important;
  }

  button.plain {
    display: none !important;
  }

  iframe.start_chat_button {
    display: none;
  }

  #Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window {
    display: none;
  }
}

.grid-container {
  padding-left: 1rem;
  padding-right: 1rem;

  @include screen-sm {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.gtmLcmOuter {
  bottom: 1.25rem !important;
  right: 1.25rem !important;
}
